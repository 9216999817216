import React, { FC } from "react";
import Helmet from "react-helmet";

import Favicon from "images/favicon.png";
import FallbackImage from "images/cover.png";

interface Props {
  title?: string;
  description?: string;
  image?: string;
}

const siteName = "FreeTime";

const MetaData: FC<Props> = ({
  title = siteName,
  description = "FreeTime creates original audio programming, working with individuals, companies and organizations to tell stories that reflect their curiosity, passions, processes, goals, values and news.",
  image = FallbackImage,
}) => (
  <Helmet>
    <link rel="icon" type="image/png" href={Favicon} />
    <title>
      {title.length && title !== siteName ? `${title} | ${siteName}` : siteName}
    </title>
    <meta name="description" content={description} />
    <meta name="description" content={description} />
    <meta property="og:title" content={title} />
    <meta property="og:description" content={description} />
    <meta property="og:type" content="website" />
    {image && <meta property="og:image" content={image} />}
    <meta property="og:site_name" content={siteName} />
  </Helmet>
);

export default MetaData;
